import React, { useRef, useContext, lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { CustomerLogos } from '../components/CustomerLogos';
import { PricingBody } from '../components/Pricing/PricingBody';
import { Testimonials } from '../components/Testimonials';
import { Hero } from '../components/General/Hero';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formBackground: {
		background: theme.workwaveBlue,
		paddingBottom: '2rem',
		marginTop: '-7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-12rem',
		},
	},

	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '14rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0',
		},
	},
}));

const PricingPage = ({ data, location }) => {
	const pricing = data.pricing.edges[0].node;
	const classes = useStyles();
	const inputRef = useRef();
	const formRef = useRef(null);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const handleInputScroll = (e) => {
		e.preventDefault();
		inputRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const med = useMediaQuery('(max-width: 960px)');
	const {
		metaTitle,
		metaDescription,
		hero,
		pricingCta,
		formBgImage,
		marketoId,
		pardotUrl,
		contactForm,
		resourceTitle,
		resources,
		_rawResourceBody,
		heroImage,
		softwareFeatures,
		_rawIntro,
		pricingCards,
	} = pricing;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<PricingBody
				softwareFeatures={softwareFeatures}
				intro={_rawIntro}
				pricingCta={pricingCta}
				pricingCards={pricingCards}
			/>
			{/* <div
				style={{
					background: 'transparent',
					padding: '2rem 0',
				}}>
				<Testimonials
					header={testimonialHeader}
					testimonials={testimonial}
					formRef={formRef}
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container> */}
			<WaveDownSVG fill='#FFFFFF' />

			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			<WaveUpSVG fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query PricingPageQuery {
		pricing: allSanityPricing {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					_rawIntro
					pricingCards {
						icon
						accentColor {
							hexValue
						}
						title
						discount
						header
						content
					}
					# testimonialTitle
					# testimonial {
					# 	title
					# 	header
					# 	testimonialText
					# 	nameAndTitle
					# 	company
					# 	image {
					# 		asset {
					# 			gatsbyImageData(placeholder: BLURRED)
					# 		}
					# 	}
					# }
					# customerLogosHeader
					# customerLogos {
					# 	title
					# 	logo {
					# 		asset {
					# 			gatsbyImageData(placeholder: BLURRED)
					# 		}
					# 	}
					# }
					softwareFeatures {
						_id
						title
						comparisonHeader
						featureListCta
						# logos {
						# 	asset {
						# 		gatsbyImageData(placeholder: BLURRED, height: 85)
						# 	}
						# }
						# associatedProducts {
						# 	cards {
						# 		title
						# 	}
						# }
						alternateTextHeaders
						features {
							featureTitle
							featureDescription
							colOne
							colTwo
							colThree
						}
						# ctaLineTitle
						# ctaRows {
						# 	ctaText
						# 	ctaLink
						# }
						expandText
						hideText
						expandedListHeader
						expandedListSubheader
					}
					pricingCta {
						ctaHeader
						ctaSubheader
						ctaText
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaLink
						internalLink
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					marketoId
					pardotUrl
					resources {
						title
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						blurb
						ctaText
						ctaLink
					}
					resourceTitle
					_rawResourceBody
				}
			}
		}
	}
`;

export default PricingPage;

import React from 'react';

import { Grid } from '@material-ui/core';

import { Container, makeStyles } from '@material-ui/core';
import { SoftwareFeatures } from './SoftwareFeatures';
import { PricingIntro } from './PricingIntro';
import { CtaCard } from '../General/CtaCard';
import { PricingCard } from './PricingCard';

const useStyles = makeStyles((theme) => ({
	cards: {
		padding: '6rem 0 4rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '2rem 0',
		},
	},
}));

export const PricingBody = ({
	softwareFeatures,
	intro,
	pricingCta,
	pricingCards,
}) => {
	const {
		ctaHeader,
		ctaSubheader,
		ctaText,
		ctaBackground,
		ctaLink,
		internalLink,
	} = pricingCta;
	const classes = useStyles();
	return (
		<>
			<Container
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
				<PricingIntro intro={intro} />
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					className={classes.cards}
					spacing={4}>
					{pricingCards.map((card, index) => (
						<PricingCard card={card} key={index} />
					))}
				</Grid>
				<SoftwareFeatures softwareFeatures={softwareFeatures} />
				<CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
					ctaLink={ctaLink}
					internalLink={internalLink}
				/>
			</Container>
		</>
	);
};
